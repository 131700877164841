import { createContext, useState, useEffect } from "react";
import { fetch } from "../utils/fetch";
// memory space created
export const GlobalContextMemorySpace = createContext();


export const GlobalContext = ({children}) =>{

    const [lang, setLang] = useState('es');
    const [showLoaderHomePage, setShowLoaderHomePage] = useState(true)
    const [MainLoader, setMainLoader] = useState(true)
    const [NavBarSectionFetchData, setNavBarSectionFetchData] = useState(); 
    const [HeroSectionFetchData, setHeroSectionFetchData] = useState();
    const [BotonesSectionFetchData, setBotonesSectionFetchData] = useState();
    const [PlanesSectionFetchData, setPlanesSectionFetchData] = useState();
    const [NovedadesSectionFetchData, setNovedadesSectionFetchData] = useState();
    const [TeleconsultasSectionFetchData, setTeleconsultasSectionFetchData] = useState();
    const [FooterSectionFetchData,setFooterSectionFetchData]=useState();


    /* CONTENIDO NAVBAR */
    const NavBarSectionGetContenido = async () => {
        try {
            const { data } = await fetch(`menus?_locale=${lang}&onOff=true&eliminado=false&_sort=orden:ASC`, "GET");
            return data;

        } 
        catch (error) {
            
            return {error: error};

        }
    };

     /* CONTENIDO FOOTER */
     const FooterSectionGetContenido = async () => {
        try {
            const { data } = await fetch(`footers?estado=PU`, "GET");
            return data;

        } 
        catch (error) {
            
            return {error: error};

        }
    };


    /* CONTENIDO HEROSECTION */
    const HeroSectionGetContenido = async () => {
        try {
            const { data } = await fetch("home-slides?onOff=true&eliminado=false&_sort=orden:ASC", "GET");
            return data;
        } 
        catch (error) {
            
            return {error: error};

        }
    };

    /* CONTENIDO BOTONES SECTION */
    const BotonesSectionGetContenido = async () => {
        try {
          const { data } = await fetch("botones-homes?eliminado=false", "GET");
            return data;

        } catch (error) {
            return {error: error};
        }
    };

    /* CONTENIDO PLANES SECTION*/
    const PlanesSectionGetContenido = async () => {
        try {
          const { data } = await fetch("planes?onOff=true&eliminado=false&_sort=orden:ASC", "GET", );
          return data;
    
        } 
        catch (error) {
            return {error: error};
        }
    };

    /* CONTENIDO NOVEDADES SECTION*/
    const NovedadesSectionGetContenido = async () => {

        try {
            const data=await fetch(`novedad/destacadas`, 'GET');
            return data.data.data;
            
        } catch (error) {
            return {error: error};
        }
    };

    /* CONTENIDO TELECONSULTA SECTION*/
    const TeleconsultasSectionGetContenido = async () => {
        try {
            const { data } = await fetch("seccion-home-dinamicas?onOff=true&eliminado=false&_sort=orden:ASC", "GET");
            return data;

        } 
        catch (error) {
            return {error: error};
        }
    };


    /* TURN OFF LOADER */
    const checkFetch = () => {

        if(!HeroSectionFetchData && !BotonesSectionFetchData && !PlanesSectionFetchData && !NovedadesSectionFetchData && !TeleconsultasSectionFetchData  && !FooterSectionFetchData){
            setTimeout(() => {
                setShowLoaderHomePage(false)
            }, 1000);
            //setShowLoaderHomePage(false)
        }

    }

    useEffect(()=>{
        //Mount: 

        NavBarSectionGetContenido().then((res)=>{res.error ? <></> :setNavBarSectionFetchData(res)}).finally(()=>setMainLoader(false));
        HeroSectionGetContenido().then((res)=>{res.error ? <></> : setHeroSectionFetchData(res)}).finally(()=>checkFetch());
        BotonesSectionGetContenido().then((res)=>{res.error ? <></> :setBotonesSectionFetchData(res)}).finally(()=>checkFetch());
        PlanesSectionGetContenido().then((res)=>{res.error ? <></> :setPlanesSectionFetchData(res)}).finally(()=>checkFetch());
        NovedadesSectionGetContenido().then((res)=>{res.error ? <></> :setNovedadesSectionFetchData(res)}).finally(()=>checkFetch());
        TeleconsultasSectionGetContenido().then((res)=>{res.error ? <></> :setTeleconsultasSectionFetchData(res)}).finally(()=>checkFetch());
        FooterSectionGetContenido().then((res)=>{res.error?<></>:setFooterSectionFetchData(res[0])})

        
        //console.log("")
        //setHeroSectionFetchData(data1)
        
        // Promise.all([fetch("home-slides/12", "GET"), fetch(`novedades?_locale=es&onOff=true&destacado=true`, 'GET')]).then(res => {
        //     console.log("TESTStTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT")
        //     console.log(res[0].data);
        //     console.log(res[1]);
        //   }).catch(reason => {

        //     console.log(reason)
        //   });

        return () =>{
        //Unmount
    
        }
      }, [])


    return(
        <GlobalContextMemorySpace.Provider 
            value={
                {
                    checkFetch,
                    MainLoader, setMainLoader,
                    NavBarSectionFetchData, setNavBarSectionFetchData,
                    showLoaderHomePage, setShowLoaderHomePage,
                    HeroSectionFetchData, setHeroSectionFetchData,
                    BotonesSectionFetchData, setBotonesSectionFetchData,
                    PlanesSectionFetchData, setPlanesSectionFetchData,
                    NovedadesSectionFetchData, setNovedadesSectionFetchData,
                    TeleconsultasSectionFetchData, setTeleconsultasSectionFetchData,
                    FooterSectionFetchData,setFooterSectionFetchData
                }}>
            {children}
        </GlobalContextMemorySpace.Provider>
    );
}